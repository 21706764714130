/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react'
import ThemeContext from '../context/ThemeContext'

export default ({children}) => (
  <ThemeContext.Consumer>
    {theme => (
      <div
        className={theme.dark ? 'light' : 'dark'}
        style={{padding: '2rem 1rem 1rem 1rem'}}
      >
        <div
          className="top-bar"
          style={{
            zIndex: '2',
            backgroundImage: theme.dark
              ? 'linear-gradient(var(--orange), rgba(0,0,0,0))'
              : 'linear-gradient(black, rgba(0,0,0,0))',
          }}
        >
          <p id="Copyright">&copy; 2016 - {new Date().getFullYear()}</p>

          <div className="switch-container">
            <label className="switch">
              <input type="checkbox" onClick={theme.toggleDark} />
              <span className="slider round" />
            </label>
          </div>
        </div>

        {children}

        <div
          className="bottom-bar"
          style={{
            backgroundImage: theme.dark
              ? 'linear-gradient(rgba(0,0,0,0), var(--orange))'
              : 'linear-gradient(rgba(0,0,0,0), black',
          }}
        />
      </div>
    )}
  </ThemeContext.Consumer>
)
